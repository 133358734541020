import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Note Modules",
  "time": "stuff",
  "index": 8,
  "type": "project",
  "hidden": false,
  "splash": "Create & organize technical documents without duplicating information (and giving everyone a headache)"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://notes.davidpeet.me"
      }}>{`Note Modules`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C++`}</inlineCode>{` `}<inlineCode parentName="p">{`Angular`}</inlineCode>{` `}<inlineCode parentName="p">{`Python`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/Note-Modules" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Human experiences lend themselves to storage in a graph style data-structure. Unfortunately such a graph is difficult to construct and even more difficult to maintain as references between nodes must be updated so frequently.`}</p>
    <p>{`I wrote note modules (... `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` ... haha see what I did there?) to obtain some of the benefits of a graph storage of knowledge. This includes improved modularization of notes as well as prevention of data duplication. In all, Note Modules provides:`}</p>
    <ul>
      <li parentName="ul">{`A preprocessing stage to remove questionable semantic choices in the markdown specification`}</li>
      <li parentName="ul">{`Latex Math rendering`}</li>
      <li parentName="ul">{`Note composition - view the content of one note inline inside of another`}</li>
      <li parentName="ul">{`Versioning / source control`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      